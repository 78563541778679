/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  color: #212121;
}

h1,
h2,
h3,
h4 {
    letter-spacing: 3px;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: #212121;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
    text-decoration: none;
    color: inherit;
}

.App {
    font-family: "Montserrat", sans-serif;
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;
    height: 100vh;
    gap: 10px;
    height: 100vh;
}

.menu {
    background-color: #F4F4F4;
    grid-column: 1/2;
    grid-row: -1/-4;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3em;
    padding-top: 6rem;
}
.menu--header {
  margin: 1em;
}



.menu--icons {
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 1em;
    /* align-items: center; */
}

.menu--contact-link {
    display: block;
    margin: .25em;
    color:#2121217a;
    transition: transform 0.3s ease-out;
}

.icon-mail-alt {
  font-size: 90% !important; 
  margin-left: 3.5px;
}


.menu--contact-link:hover {
    color: #212121;
    transform: scale(1.1);
}

.menu--languages  {
  text-align: center;
}

.welcome {
    grid-column: 2/3;
    margin-top: 2em;
    text-align: center;
    display: flex;
}

.welcome--name-box {
    border: 2.5px groove gray;
    border-radius: 3px;
    background-color: white;
    width: fit-content;
    height: fit-content;
    padding: 2.3em 4em;
    position: relative;
    right: 4em;
    box-shadow: 5px 5px 5px rgba(127, 127, 127, 0.3);
}


.welcome h1 {
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: bold;
}

.welcome h4 {
    color: #164b34;
}

.projects {
    padding: 1em;
    grid-column: 2/4;
    display: grid;
    grid-template-columns: 2fr 1.5fr 1fr;
    grid-template-rows: repeat(3, auto);
    column-gap: 10px;
}

.projects > * {
    margin-bottom: 20px;

}

h3 {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 2px;
  margin-bottom: 1em;
  display: block;
  text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
  font-size: 1em;
}

h4 {
  font-size: .90em;
  margin: 1em 0;
}

.project--link {
    display: block;
    width: fit-content;
    padding: 5px 0;
    cursor: pointer;
    border-style: none;  
}

.project--description {
 
}

.project--description:hover {
  /* border: 5px solid white; why is it bouncing so much? */
  font-weight: 700;
}
/* .project--description:hover::before {
  content: "< ";

}
.project--description:hover::after {
  content: " />";

} */

.projects--tile {
  
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  padding:1.5em;
  opacity: 0;
  transition: opacity 1.5s;
  background-color: #F4F4F4;
  box-shadow: 5px 5px 5px rgba(127, 127, 127, 0.3);
       
  
}
.activeProject {
    opacity: 1;
    transition: opacity  ease-in-out 1s;
    padding: 1rem; 
}

.projects-tile--content {
  display: grid;
  grid-template: 3 / 3;
}

.projects-tile--content p {
  font-size: .8em;
  text-transform: none;
}

.projects-tile--content > h3 {
  text-transform: uppercase;
}

.projects-tile--buttons {
text-align: center;
}

.projects-tile--button {
  background-color: #F4F4F4;
  max-width: 50%;
  margin: 1em 0 0 0;
  padding: 0 1em;
  border: none;
  cursor: pointer;
  transition: box-shadow ease-in-out 0.3s;
  }

.projects-tile--button:hover {
 box-shadow: 1px 1px 5px rgba(127, 127, 127, 0.705);
}

.link-box {
  display: flex;
  justify-content:space-around;
}
.projects-tile--link {
  background-color: #F4F4F4;
  max-width: 100%;
  /* margin: 1em 1em; */
  padding: 1em;
  border: none;
  cursor: pointer;
  transition: box-shadow ease-in-out 0.3s;
}
.projects-tile--link:hover {
  box-shadow: 1px 1px 5px rgba(127, 127, 127, 0.705);
  
 }


.projects--certificates {
  display: flex;
  flex-direction: column;
  width: fit-content;
 
}


.projects--certificate-link {
    display: block;
    width: fit-content;
    padding: 5px 0;
    
}

.projects--certificate-link:hover  {
  font-weight: 700;
}

.placeholder {
  grid-row: 1 / 3;
  grid-column: 3 / 4;
}

.footer {
  /* Change */
      
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
}

.footer-text {
       
font-size: 0.8em;
}

@media (max-width: 600px) {

  .App {
    grid-template-columns: 1fr 7fr;
  }

  .welcome--name-box {
    position: static;
    right: 0;
  padding: 1em;
  }
  .welcome h1 {
    font-size: 1.2em;
  }
  .welcome h4 {
    font-size: 0.8em;
  }

  .menu--header {
    display: none;
  }

  .placeholder {
  
  }
  .projects {
    padding: 0.5em;
    display: flex;
    flex-direction: column;
  }
  .projects--tile {
  padding: 0rem;
  }

  .activeProject {
   
    padding: 1rem; 
}

}
